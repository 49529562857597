@font-face {
font-family: '__maisonNeue_f041e4';
src: url(/_next/static/media/2970c5dd313cc946-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__maisonNeue_f041e4';
src: url(/_next/static/media/b5747ae9db5cacc8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__maisonNeue_f041e4';
src: url(/_next/static/media/b5747ae9db5cacc8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__maisonNeue_Fallback_f041e4';src: local("Arial");ascent-override: 97.69%;descent-override: 33.09%;line-gap-override: 0.00%;size-adjust: 101.54%
}.__className_f041e4 {font-family: '__maisonNeue_f041e4', '__maisonNeue_Fallback_f041e4'
}.__variable_f041e4 {--font-maison: '__maisonNeue_f041e4', '__maisonNeue_Fallback_f041e4'
}

